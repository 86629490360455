import { graphql, useStaticQuery } from "gatsby"

const useFeaturedArticle = () => {
  const {
    allContentfulPost: { nodes },
  } = useStaticQuery(graphql`
  query featuredArticleLinksQuery {
    allContentfulPost(
      filter: { featureGuide: { eq: true } }
      sort: { fields: url }
    ) {
      nodes {
        title
        gatsbyPath(filePath: "/articles/{contentfulPost.url}")
        featureGuide
        introduction
        headerImage {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
  `)

  return nodes
}

export default useFeaturedArticle
